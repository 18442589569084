import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: '#eceff4',
        height: '100%',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box'
      },
      // styles for the `a`
      a: {
        color: 'teal.500',
        _hover: {
          textDecoration: 'underline'
        }
      }
    }
  }
});
