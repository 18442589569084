import { Box, ChakraProvider } from '@chakra-ui/react';
import Home from './screens/home/Home';
import { theme } from './theme';

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Box display={'flex'} justifyContent="center">
        <Home />
      </Box>
    </ChakraProvider>
  );
};

export default App;
