import {
  HiringBox,
  HiringCircle,
  JobMark,
  JobTitle,
  JobTitleText,
  ProfileView,
  TitleBox,
  ProfileBox,
  ImageBox,
  ProfileTitleText,
  ProfileDescriptionText,
  ProfileDescriptionBox,
  CompanyLinkText
} from './ProfileCard.styles';
import { Image } from '@chakra-ui/react';

const ProfileCard = () => {
  return (
    <ProfileView>
      <TitleBox>
        <JobTitle>
          <JobMark />
          <JobTitleText>Software Engineer</JobTitleText>
        </JobTitle>
        <HiringBox>
          <HiringCircle />
        </HiringBox>
      </TitleBox>

      <ProfileBox>
        <ImageBox>
          <Image
            borderRadius="full"
            boxSize="160px"
            border={'2px solid #edeff4'}
            padding={'0.5rem'}
            src="https://framerusercontent.com/images/dDnqkzQfB3pmvEdrFSxAJSkAyyk.png"
            alt="Dan Abramov"
          />
        </ImageBox>

        <ProfileTitleText>{`I'm Can Cati`}</ProfileTitleText>
        <ProfileDescriptionBox>
          <ProfileDescriptionText>
            React & React Native Developer from Istanbul.
          </ProfileDescriptionText>
          <ProfileDescriptionText mt={'0.25rem'}>
            Currently developing at{' '}
            <CompanyLinkText target="_blank" href="https://innovance.com.tr/">
              Innovance
            </CompanyLinkText>
          </ProfileDescriptionText>
        </ProfileDescriptionBox>
      </ProfileBox>
    </ProfileView>
  );
};

export default ProfileCard;
